.App {
  text-align: center;
  
}

.block {    
  max-height: 250px;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;  
  flex-direction: column;
}

.block:hover {    
  
}

.block-selected {    
  color: rgba(30,190,30,1) !important;
}

.block-text {
  color: white;
  font-size: 24px;
  padding-top: 20px;
  padding-bottom: 20px;  
  user-select: none;
}

.block-shade {
  
  width: 100%;  
}

.hidewhenwide {
  display: auto;
}

.hidewhennarrow {
  display: auto;
}

.opacity-hover {
  opacity: 1;
}

.opacity-hover:hover {
  opacity: 0.9;
}

.page-content {
  width: 385px;
  font-family: 'Love Ya Like A Sister';
  position: relative;
  padding-bottom: 30px;  
  margin-bottom: 40px;
  text-align: center;
  
}

@media only screen and (min-width: 1100px) {
  .hidewhenwide {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .hidewhennarrow {
    display: none;
  }  
}

@media only screen and (max-width: 600px) {
  .hidewhennarrow {
    display: none;
  }  
  .page-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}